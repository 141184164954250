.PasswordLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  // background-image: url("/src/Assets/images/login-background.svg"); // todo not working

  .PasswordLayout--wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .PasswordLayout__title {
      margin: 0 0 80px 0;

      .anticon {
        display: block;

        svg {
          width: 100%;
          max-width: 350px;
          height: auto;
        }
      }
    }

    .PasswordLayout__content {
      position: relative;
      max-width: 590px;
      margin: 0 auto;
      padding: 100px 78px;
      border-radius: 20px;
      box-shadow: 0px 20px 40px rgba(47, 52, 55, 0.1);

      .PasswordLayout__content__link {
        position: absolute;
        top: 20px;
        left: 24px;
        display: flex;
        align-items: center;
        color: @c-font-secondary;

        .anticon {
          font-size: 2em;
          color: @c-theme-regular;
        }
      }

      .PasswordLayout__content__title {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 700;
        text-align: center;
      }

      .PasswordLayout__content__subTitle {
        margin-bottom: 40px;
        text-align: center;
        color: @c-font-secondary;
      }
    }
  }

  .PasswordLayout__footer {
    text-align: center;
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;