.BaseTable {
  .BaseTable__search {
    .anticon {
      font-size: 1.7em;
      color: @c-theme-regular;
    }
  }

  .BaseTable__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4em;
    font-size: 2.28em;
    font-weight: 400;
    color: @c-font-secondary;
  }

  .ant-table-wrapper {
    @pagination-position: 80px;

    padding-top: @pagination-position;

    .ant-pagination {
      position: absolute;
      top: -@pagination-position+20;
      left: 0;
      width: 100%;
      margin: 0;

      .ant-select {
        .ant-select-selector {
          height: 40px;
          display: flex;
          align-items: center;
          border: 0;
          border-radius: 4px;
          box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.05);
        }
      }

      li {
        order: 1;
        box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.05);
        margin: 0px 10px;
        border: 0;
        width: auto;
        height: auto;

        a,
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          padding: 0;
          font-weight: 700;
          font-size: initial;
          border: 0;
          border-radius: 4px;
          font-family: @font-family;

          &:hover {
            color: @c-font-primary;
            background-color: @c-theme-regular;
          }
        }
        &.ant-pagination-item-active {
          a,
          button {
            color: @c-font-light;
            background-color: @c-theme-regular;
          }
        }

        &.ant-pagination-options {
          order: 0;
          margin: 0 auto 0 0;
        }
      }
    }

    .ant-table {
      overflow: hidden;
      box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.05);
      border-radius: 10px;

      .ant-table-thead {
        tr {
          .ant-table-cell {
            background-color: @c-background-secondary;
            font-size: initial;
            font-weight: 700;
            color: @c-font-light;

            &:nth-of-type(2) {
              width: 30%;
              min-width: 280px;
            }

            .ant-table-column-sorter-inner {
              .anticon {
                color: @c-font-light;

                &.active {
                  color: @c-theme-regular;
                }
              }
            }
          }
        }
      }

      tr {
        cursor: pointer;

        td {
          padding: 10px 16px;

          &.ant-table-column-sort {
            background-color: initial;
          }
        }

        &:nth-child(even) {
          td {
            background-color: @c-background-primary;
          }
        }

        &:hover {
          td {
            background-color: @c-theme-light;
          }
        }
      }
    }
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;