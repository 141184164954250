.BottomButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .ant-btn {
    display: block;
    width: 47%;
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;