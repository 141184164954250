html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: @font-family;
  font-size: 16px;
  font-weight: 400;
  color: @c-font-primary;

  ::selection {
    color: @c-font-light;
    background: @c-theme-regular;
  }
}

a {
  color: @c-link;

  &:hover {
    color: @c-link-hover;
  }
}

.ant-spin {
  .ant-spin-dot-item {
    background-color: @c-theme-regular;
  }
}

.ant-divider {
  border-color: @c-border;
  border-top-width: 2px;
}

.ant-layout-content {
  padding: 40px;
}

.ant-btn:not(.ant-btn-icon-only) {
  min-width: 180px;
  height: 48px;
  font-weight: 700;
  padding: 0 8px;
  border-radius: 10px;
  color: @c-font-secondary;
  border-color: @c-font-secondary;

  &:focus {
    color: @c-font-secondary;
    border-color: @c-font-secondary;
  }

  a& {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: @c-font-secondary;
    border-color: @c-font-secondary;
  }

  .anticon {
    font-size: 1.5em;
    vertical-align: bottom;
  }

  &.ant-btn-primary {
    color: @c-font-light;
    border-color: @c-theme-regular;
    background-color: @c-theme-regular;

    &[disabled] {
      background-color: @c-background-primary;
      border-color: @c-background-primary;
      color: @c-font-disabled;

      svg {
        path {
          fill: @c-font-disabled;
        }
      }
    }
  }
}

.ant-form-item {
  .ant-form-item-label {
    padding-bottom: 2px;

    label {
      font-size: 13px;
    }
  }

  .ant-form-item-control-input-content {
    & > .ant-input {
      height: 52px;
      border-radius: 4px;
      border-color: @c-border;

      &:not(.ant-input-disabled):hover {
        border-color: @c-theme-regular;
      }
    }
  }

  .ant-input-affix-wrapper {
    height: 52px;
    border-radius: 4px;
    border-color: @c-border;

    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: @c-theme-regular;
    }

    .ant-input-prefix {
      .anticon {
        margin-right: 10px;
        font-size: 20px;
        color: @c-theme-regular;
      }
    }
  }
}

.col-quill {
  padding-top: 24px;

  .quill {
    .ql-toolbar {
      border-radius: 4px 4px 0 0;
      border-color: @c-border;
    }

    .ql-container {
      border-radius: 0 0 4px 4px;
      border-color: @c-border;
    }

    &:hover {
      .ql-toolbar {
        border-color: @c-theme-regular;
        border-bottom-color: @c-border;
      }

      .ql-container {
        border-color: @c-theme-regular;
      }
    }

    .ql-editor {
      min-height: 200px;
      resize: vertical;
    }
  }

  .ant-form-item-label {
    position: absolute;
    top: 0;
    left: 20px;
  }

  .ant-form-item-control {
    position: static;

    .ant-form-item-control-input {
      position: absolute;
      top: 30px;
      right: 22px;
    }
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;