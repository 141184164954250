.SideNav {
  display: flex;
  flex-direction: column;
  background-color: @c-background-neutral;
  box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.05);

  .SideNav__logo {
    display: block;
    padding: 42px 0;
    border-bottom: 2px solid @c-border;

    .anticon {
      display: block;

      svg {
        width: 100%;
        max-width: 180px;
        height: auto;
      }
    }
  }

  .SideNav__profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;

    .SideNav__profile__name {
      font-weight: 700;
    }

    .SideNav__profile__link {
      font-weight: 700;
      color: @c-theme-regular;
    }
  }

  .SideNav__link {
    @left-bar-width: 5px;

    position: relative;
    display: flex;
    align-items: center;
    margin: 1px 0;
    padding: 9px 20px;
    color: @c-font-primary;
    transition: all 0.2s linear;

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: -@left-bar-width;
      width: @left-bar-width;
      height: 100%;
      background-color: @c-theme-regular;
      transition: left 0.2s linear;
    }

    .anticon {
      font-size: 2em;
      margin-right: 6px;
    }

    &.SideNav__link--logout {
      margin-top: auto;
      height: auto;
      border: 0;
      background: transparent;
      cursor: pointer;
    }

    &:hover {
      &::before {
        left: 0;
      }
    }

    &.active {
      background-color: @c-theme-light;
      color: @c-theme-dark;

      &::before {
        left: 0;
      }
    }
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;