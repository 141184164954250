.ImagePicker {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
  }

  .ant-upload-list-item-list-type-picture-card {
    padding: 0px;
  }

  .ant-upload-select-picture-card {
    width: 200px;
    margin: 0;
    background-color: @c-background-neutral;
  }

  .ant-upload-list-picture-card-container {
    margin: 0;
  }

  .for-icon {
    a.ant-upload-list-item-thumbnail {
      img {
        object-fit: contain;
      }
    }
    .ant-upload-list-item-list-type-picture-card {
      padding: 8px;
    }
    .ant-upload-list-picture-card-container {
      background-color: @c-background-secondary;
    }
  }

  .upload-icon {
    font-size: 24px;
  }
}

@light-00: #ffffff;@light-01: #f7f7f7;@light-02: #d8d8d9;@dark-00: #000000;@dark-01: #141414;@dark-02: #4e4f51;@green-00: #5e611a;@green-01: #b3bd35;@green-02: #f8f9e7;@blue-00: #049bc5;@c-font-light: @light-00;@c-font-primary: @dark-01;@c-font-secondary: @dark-02;@c-font-disabled: @light-02;@c-background-neutral: @light-00;@c-background-primary: @light-01;@c-background-secondary: @dark-02;@c-border: @light-01;@c-theme-dark: @green-00;@c-theme-regular: @green-01;@c-theme-light: @green-02;@c-link: @blue-00;@c-link-hover: @c-theme-regular;@font-family: Arial, Helvetica, sans-serif;@side-nav-width: 230px;@header-height: 100px;@article-footer-height: 100px;